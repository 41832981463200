require("./bootstrap");
require("../../node_modules/admin-lte");
require("select2");

import {createApp, h} from "vue";
import {createInertiaApp, Head, Link} from "@inertiajs/inertia-vue3";
import {InertiaProgress} from "@inertiajs/progress";

/*  font awesome icon  */
import {dom, library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
// Rotas do Laravel em JavaScript
import route from "../../vendor/tightenco/ziggy/src/js";
import moment from "moment";
import {maska} from "maska";
import * as dayjs from "dayjs";
import swal from "sweetalert2";
import {Popover, Tooltip} from "bootstrap";

library.add(fas, far, fab);
dom.watch();

console.log("ASSET_URL-----------", process.env.ASSET_URL);
console.log("MIX_APP_URL-----------", process.env.MIX_APP_URL);

window.swal = swal;
/*import toastr from "toastr";

toastr.options = {
    showConfirmButton: false,
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    progressClass: "toast-progress",
    positionClass: "toast-bottom-right",

    rtl: false,
    tapToDismiss: true,
    preventDuplicates: false,
    onclick: null,
    showDuration: 300,
    hideDuration: 100,
    timeOut: 5000,
    timer: 2000,
    extendedTimeOut: 1000,
    hideEasing: "linear",
    hideMethod: "fadeOut",
    showEasing: "swing",
    showMethod: "fadeIn"
};


window.toastr = Toast;*/
window.Tooltip = Tooltip;
window.Popover = Popover;

$('[data-toggle="popover"]').popover();
$('[data-toggle="tooltip"]').tooltip();

InertiaProgress.init({
    // The delay after which the progress bar will
    // appear during navigation, in milliseconds.
    delay: 250,
    // The color of the progress bar.
    color: "#50a",
    // Whether to include the default NProgress styles.
    includeCSS: true,
    // Whether the NProgress spinner will be shown.
    showSpinner: true,
});

let urlCurrente = window.location.href;
if (urlCurrente !== route("login")) {
    createInertiaApp({
        title: (title) => (title ? `${title} - SGP MPC/PA` : "SGP MPC/PA"),
        //  resolve: name => require(`./Pages/${name}`),
        resolve: (name) => {
            const importPage = import(`./Pages/${name}`);
            if (!importPage) {
                throw new Error(
                    `Página desconhecida  ${name} Está localizada em Páginas com extensão .vue`
                );
            }
            return typeof importPage === "function" ? importPage() : importPage;
        },

        setup({el, app, props, plugin}) {
            createApp({
                render: () => h(app, props),
            })
                .use(plugin, dayjs)
                .directive("maska", maska)
                .component("Head", Head)
                .component("Link", Link)
                .component("font-awesome-icon", FontAwesomeIcon)
                .mixin({
                    methods: {
                        route,
                        temPermissao: function (permissoes) {
                            let todasPermissoes = this.$page.props.auth.can;
                            let temPermissao = false;
                            permissoes.forEach(function (item) {
                                if (todasPermissoes[item]) temPermissao = true;
                            });
                            return temPermissao;
                        },
                        temPapeis: function (roles) {
                            let papeis = this.$page.props.auth.roles;

                            let toReturn = false;

                            roles.map((item) => {
                                if (papeis.includes(item)) toReturn = true;
                            })

                            return toReturn;
                        },
                        formatDate: function (date, format = 'DD/MM/YYYY') {
                            if (!date) {
                                return "";
                            }

                            return moment(String(date)).format(format);
                        },
                    },
                })
                .mount(el);
        },
    });
}
